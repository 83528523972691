.container {
    display: flex;
    justify-content: space-between;
    padding: 20px;
    height: 100vh;
    /* overflow-x: none; */
}

.text {
    /* flex: 1; */
    height: 100vh;
    width: 70%;
    padding: 10px;
    border: 1px solid #ddd;
    background-color: #f7f7f7;
    white-space: pre-line;
    overflow: auto;
}

.answers {
    flex: 1;
    padding: 10px;
    border: 1px solid #ddd;
    background-color: #e3e3e3;
    height: 100vh;
    width: 30%;
    overflow: auto;
}

.highlight {
    background-color: yellow;
    font-weight: bold;
  }

.btn {
    border-radius: 40px;
    border: none;
    background-color: rgb(39, 177, 227);
    color: white;
    padding: 10px;
    margin: 10px 0;
    cursor: pointer;
}

.btn:hover {
    background-color: rgb(18, 154, 203);
}
/* 
h2 {
    font-size: 20px;
}

ul {
    list-style: none;
    padding: 0;
}

li {
    margin-bottom: 10px;
} */
