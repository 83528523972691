.btn {
    border-radius: 40px;
    border: none;
    background-color: rgb(39, 177, 227);
    color: white;
    padding: 10px;
    margin: 10px 5px;
    cursor: pointer;
}

.btn:hover {
    background-color: rgb(18, 154, 203);
}

.timer {
    margin: 30px 40px;
}